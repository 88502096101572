import React from "react";
import { Link } from "react-router-dom";
import {
  FaLinkedin,
  FaFacebookSquare,
  FaTwitterSquare,
  FaInstagramSquare,
  FaLocationArrow,
} from "react-icons/fa";
import logo from "../../assets/img/hb_logo.png";
import ScrollToTopButton from "./ScrollToTopButton";

const Footer = () => {
  return (
    <>
      <footer id="rs-footer" className="rs-footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-12 col-sm-12 footer-widget">
                <div className="footer-logo mb-30">
                  <Link to="#">
                    <img src={logo} alt="Logo" />
                  </Link>
                </div>
                <div className="textwidget pb-30">
                  <p>
                    HashedBit Innovations works with innovative ideas and having
                    a crazy attitude towards our work; provides inexpensive yet
                    effective array of total web and software solutions.
                  </p>
                </div>
                <ul className="footer-social md-mb-30">
                  <li>
                    <a
                      className="icon-tilinkedin"
                      href="https://www.linkedin.com/company/hashedbit-innovations/"
                      target="_blank"
                    >
                      <span>
                        <i className="fa fa-linkedin"></i>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      className="icon-tifacebook"
                      href="https://www.facebook.com/hashedbitinnovations"
                      target="_blank"
                    >
                      <span>
                        <i className="fa fa-facebook"></i>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      className="icon-titwitter"
                      href="https://twitter.com/HashedBit"
                      target="_blank"
                    >
                      <span>
                        <i className="fa fa-twitter"></i>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      className="icon-tiinstagram"
                      href="https://www.instagram.com/hashedbit/"
                      target="_blank"
                    >
                      <span>
                        <i className="fa fa-instagram"></i>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      class="icon-tiinstagram"
                      href="https://www.youtube.com/@HashedBit"
                      target="_blank"
                    >
                      <span>
                        <i class="fa fa-youtube"></i>
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12 pl-45 md-pl-15 md-mb-30">
                <h3 className="widget-title">Products</h3>
                <ul className="site-map">
                  <li>
                    <Link to="/store">Store</Link>
                  </li>
                  <li>
                    <Link to="/cart">Cart</Link>
                  </li>
                  <li>
                    <Link to="/checkout">Checkout</Link>
                  </li>
                  <li>
                    <Link to="/privacypolicy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/termsconditions">Terms & Conditions</Link>
                  </li>
                  <li>
                    <Link to="/refundreturnpolicy">Refund & Return Policy</Link>
                  </li>
                  <li>
                    <Link to="/resources">Resources</Link>
                  </li>
                  <li>
                    <Link to="/jobs">Jobs</Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12 pl-45 md-pl-15 md-mb-30">
                <h3 className="widget-title">Learning</h3>
                <ul className="site-map">
                  <li>
                    <Link to="/learning/internship">Tech Internship</Link>
                  </li>
                  <li>
                    <Link to="/learning/mbainternship">MBA Internship</Link>
                  </li>
                  <li>
                    <Link to="/learning/mern">MERN Stack</Link>
                  </li>
                  <li>
                    <Link to="/learning/placement">Placement Training</Link>
                  </li>
                  <li>
                    <Link to="/learning/collegeinternship">
                      College Internship
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12">
                <h3 className="widget-title">Contact Info</h3>
                <ul className="address-widget">
                  <li>
                    <i className="flaticon-location"></i>
                    <div className="desc">Gurgaon, Haryana, India</div>
                  </li>
                  <li>
                    <i className="flaticon-call"></i>
                    <div className="desc">
                      <a href="#">+91 - 9669787936</a>
                    </div>
                  </li>
                  <li>
                    <i className="flaticon-call"></i>
                    <div className="desc">
                      <a href="#">+91 - 9599171535</a>
                    </div>
                  </li>
                  <li>
                    <i className="flaticon-email"></i>
                    <div className="desc">
                      <a href="#">General - info@hashedbit.com</a>
                    </div>
                  </li>
                  <li>
                    <i className="flaticon-email"></i>
                    <div className="desc">
                      <a href="#">HR - hr@hashedbit.com</a>
                    </div>
                  </li>
                  <li>
                    <i className="flaticon-email"></i>
                    <div className="desc">
                      <a href="#">General - hashedbit@gmail.com</a>
                    </div>
                  </li>
                  <li>
                    <i className="flaticon-email"></i>
                    <div className="desc">
                      <a href="#">HR - hashedbitinnovations@gmail.com</a>
                    </div>
                  </li>
                  <li>
                    <i className="flaticon-clock-1"></i>
                    <div className="desc">Opening Hours: 10 AM - 7 PM</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row y-middle">
              <div className="col-lg-6 text-right md-mb-10 order-last">
                <ul className="copy-right-menu">
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  <li>
                    <Link to="/services">Services</Link>
                  </li>
                  <li>
                    <Link to="/products">Products</Link>
                  </li>
                  <li>
                    <Link to="/learning">Learning</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-6">
                <div className="copyright">
                  <p>
                    Copyright &copy;{new Date().getFullYear()} All rights
                    reserved | <Link to="/">HashedBit Innovations Pvt Ltd</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <ScrollToTopButton />

      <div id="scrollUp" className="orange-color">
        <i className="fa fa-angle-up"></i>
      </div>

      <div
        aria-hidden="true"
        className="modal fade search-modal"
        role="dialog"
        tabIndex="-1"
      >
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span className="flaticon-cross"></span>
        </button>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="search-block clearfix">
              <form>
                <div className="form-group">
                  <input
                    className="form-control"
                    placeholder="Search Here..."
                    type="text"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
