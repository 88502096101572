import React, { useState, useEffect } from "react";
import MemberImage from "../../assets/images/Portfolio/Images/1.jpg";
import axios from "axios";
import "../../assets/images/Portfolio/style.css";
import { Link } from "react-router-dom";
import loadImage from "../../assets/img/giphy.gif";

const Portfolio = () => {
  const [portfolios, setPortfolios] = useState([]);
  const [loader ,setLoader]=useState(false)
  //http://localhost:4000/portfolio/getPortfolio
  useEffect(() => {
    const fetchPortfolios = async () => {
      setLoader(true)
      try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}portfolio/getPortfolio`
      );
      const updatedPortfolio = result.data.map((portfolio) => ({
        ...portfolio,
        image: `${process.env.REACT_APP_API_URL}${portfolio.image.replace(
          /\\/g,
          "/"
        )}`,
      }));
      setPortfolios(updatedPortfolio);
    } catch (error) {
        console.log(error)
    }
    finally{
      setLoader(false)
    }
    };

    fetchPortfolios();
  }, []);

  return (
    <>
      <div class="rs-breadcrumbs img2">
        <div class="breadcrumbs-inner text-center">
          <h1 class="page-title">Portfolio</h1>
          {/* <ul>
            <li title="Braintech - IT Solutions and Technology Startup HTML Template">
              <a class="active" href="index.html">
                Home
              </a>
            </li>
            <li>Portfolio</li>
          </ul> */}
        </div>
      </div>

      {/* <!-- Portfolio Section Start --> */}
      <div class="rs-about pt-120 pb-40 md-pt-80 md-pb-80">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6 pr-40 md-pr-15 md-mb-50">
              <div class="sec-title4">
                <span class="sub-text">Portfolio</span>
                <h2 class="title">
                  We Help to Implement Your Ideas into Automation
                </h2>
                <div class="heading-line"></div>
                {/* <div class="desc desc-big">
                  We donec pulvinar magna id leoersi pellentesque impered
                  dignissim rhoncus euismod euismod eros vitae best consulting &
                  financial services theme .
                </div>
                <div class="desc">
                  Business ipsum dolor sit amet nsectetur cing elit. Suspe
                  ndisse suscipit sagittis leo sit met entum is not estibulum
                  dignity sim posuere cubilia durae. Leo sit met entum cubilia
                  crae. At vero eos accusamus et iusto odio dignissimos
                  provident nam libero tempore, cum soluta.
                </div> */}
                <div class="btn-part mt-45">
                  <Link class="readon learn-more" to="/contact">
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="software-img">
                <img src={MemberImage} alt="images" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Portfolio Section END --> */}


      {/* Previous Clients  start*/}
      <div className="container py-5">
        <h2 className="text-center mb-5">Some of our Products / Projects</h2>
        {/* Loader for Portfolio Section */}
        {loader ? (
          <div className="d-flex justify-content-center align-items-center">
            <img src={loadImage} alt="Loading..." />
          </div>
        ) : (
          <div className="row">
            {portfolios.map((portfolio, index) => (
              <div className="col-lg-4 col-md-6 mb-4" key={index}>
                <div className="card h-100 text-center shadow-sm">
                  <img
                    src={portfolio.image}
                    alt={portfolio.name}
                    className="card-img-top img-fluid"
                    style={{ height: "200px", objectFit: "cover" }}
                  />
                  <div className="card-body">
                    <h5 className="card-title">{portfolio.name}</h5>
                    <a
                      href={portfolio.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-outline-primary"
                    >
                      Visit Website
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      {/* Previous Clients  End */}
    </>
  );
};

export default Portfolio;
