import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Container, Row, Col, Card, Alert, Button, Spinner } from "react-bootstrap";
import axios from 'axios';
import { ClassificationType } from "typescript";

const StatusPage = () => {
  const { transactionId } = useParams();
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState(null);
  const [msg, setMsg] = useState(null);
  const [error, setError] = useState(null);

  const ValidatePayment = async () => {
    try {
      const url = `${process.env.REACT_APP_API_URL}payment/validate/${transactionId}`;
      const response = await axios.get(url)
      if (response.data.success===true) {
        setStatus("SUCCESS");
        setMsg("Your payment was processed successfully.")
      } else {
        setStatus("FAILURE");
        setMsg("Unfortunately, your payment was not successful. Please try again.")
      }
    } catch (error) {
      setError(error.message);
      setStatus("Unable to get details for this TransactionID");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    ValidatePayment();
  }, [transactionId]);

  return (
    <Container className="mt-5">
      <Row className="justify-content-center m-5">
        <Col md={8}>
          <Card className="text-center shadow">
            <Card.Body>
              {loading ? (
                <Spinner animation="border" />
              ) : (
                <>
                  {status === "SUCCESS" ? (
                    <>
                      <Alert variant="success">
                        <h4 className="alert-heading">Payment Successful!</h4>
                        <p>{msg}</p>
                        <hr />
                        <p className="mb-0">Transaction ID: <strong>{transactionId}</strong></p>
                      </Alert>
                      <Button href="/" variant="primary" className="mt-3">
                        Continue Shopping
                      </Button>
                    </>
                  ) : (
                    <>
                      <Alert variant="danger">
                        <h4 className="alert-heading">Payment Failed!</h4>
                        <p>{msg}</p>
                        <hr />
                        {error && <p className="mb-0">Error: {error}</p>} 
                        <p className="mb-0">Transaction ID: <strong>{transactionId}</strong></p>
                      </Alert>
                      <Link to={'/checkout'} className="btn btn-warning mt-3">
                        Retry Payment
                      </Link>
                    </>
                  )}
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default StatusPage;
